// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/billing_new/dto_order_new.proto (package com.diagnocat.billing_new, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { CalledVia, Created, RevisionNew } from "../../model/dto_base_pb.js";
import { ProductModel } from "./dto_product_new_pb.js";

/**
 * @generated from message com.diagnocat.billing_new.Order
 */
export const Order = proto3.makeMessageType(
  "com.diagnocat.billing_new.Order",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "SequentialID", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "AccountID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "Effect", kind: "message", T: Effect },
    { no: 20, name: "Billed", kind: "message", T: Order_BilledOrderKind, oneof: "Kind" },
    { no: 21, name: "Service", kind: "message", T: Order_ServiceOrderKind, oneof: "Kind" },
    { no: 30, name: "Processed", kind: "message", T: Processed },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: RevisionNew },
    { no: 10050, name: "Deleted", kind: "message", T: OrderDeleted },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.Order.BilledOrderKind
 */
export const Order_BilledOrderKind = proto3.makeMessageType(
  "com.diagnocat.billing_new.Order.BilledOrderKind",
  () => [
    { no: 1, name: "Amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DueDate", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "Invoiced", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "InvoiceID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "InvoiceURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "Paid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "Cancelled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "Overdue", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "TransactionNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "MarkedAsPaid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "Order_BilledOrderKind"},
);

/**
 * @generated from message com.diagnocat.billing_new.Order.ServiceOrderKind
 */
export const Order_ServiceOrderKind = proto3.makeMessageType(
  "com.diagnocat.billing_new.Order.ServiceOrderKind",
  [],
  {localName: "Order_ServiceOrderKind"},
);

/**
 * @generated from message com.diagnocat.billing_new.Effect
 */
export const Effect = proto3.makeMessageType(
  "com.diagnocat.billing_new.Effect",
  () => [
    { no: 1, name: "ProductStateRemove", kind: "message", T: EffectProductStateRemove, oneof: "Effect" },
    { no: 20, name: "SubscriptionAdd", kind: "message", T: EffectSubscriptionAdd, oneof: "Effect" },
    { no: 21, name: "SubscriptionRenew", kind: "message", T: EffectSubscriptionRenew, oneof: "Effect" },
    { no: 22, name: "SubscriptionReplace", kind: "message", T: EffectSubscriptionReplace, oneof: "Effect" },
    { no: 23, name: "SubscriptionActivate", kind: "message", T: EffectSubscriptionActivate, oneof: "Effect" },
    { no: 24, name: "SubscriptionSetAutoRenewal", kind: "message", T: EffectSubscriptionSetAutoRenewal, oneof: "Effect" },
    { no: 25, name: "SubscriptionSetAutoCharge", kind: "message", T: EffectSubscriptionSetAutoCharge, oneof: "Effect" },
    { no: 40, name: "AddonAdd", kind: "message", T: EffectAddonAdd, oneof: "Effect" },
    { no: 41, name: "PackageAdd", kind: "message", T: EffectPackageAdd, oneof: "Effect" },
    { no: 42, name: "BonusAdd", kind: "message", T: EffectBonusAdd, oneof: "Effect" },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.OrderDeleted
 */
export const OrderDeleted = proto3.makeMessageType(
  "com.diagnocat.billing_new.OrderDeleted",
  () => [
    { no: 1, name: "IsDeleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "By", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "At", kind: "message", T: Timestamp },
    { no: 4, name: "Via", kind: "enum", T: proto3.getEnumType(CalledVia) },
    { no: 5, name: "IsDuplicate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "DuplicateOfOrderID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.Processed
 */
export const Processed = proto3.makeMessageType(
  "com.diagnocat.billing_new.Processed",
  () => [
    { no: 1, name: "By", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "At", kind: "message", T: Timestamp },
    { no: 3, name: "Via", kind: "enum", T: proto3.getEnumType(CalledVia) },
    { no: 4, name: "IsProcessed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.EffectProductStateRemove
 */
export const EffectProductStateRemove = proto3.makeMessageType(
  "com.diagnocat.billing_new.EffectProductStateRemove",
  () => [
    { no: 1, name: "RemoveProductStateID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.EffectSubscriptionSetAutoRenewal
 */
export const EffectSubscriptionSetAutoRenewal = proto3.makeMessageType(
  "com.diagnocat.billing_new.EffectSubscriptionSetAutoRenewal",
  () => [
    { no: 1, name: "SubscriptionStateID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "IsAutoRenewal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.EffectSubscriptionSetAutoCharge
 */
export const EffectSubscriptionSetAutoCharge = proto3.makeMessageType(
  "com.diagnocat.billing_new.EffectSubscriptionSetAutoCharge",
  () => [
    { no: 1, name: "SubscriptionStateID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "IsAutoCharge", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.EffectSubscriptionAdd
 */
export const EffectSubscriptionAdd = proto3.makeMessageType(
  "com.diagnocat.billing_new.EffectSubscriptionAdd",
  () => [
    { no: 1, name: "Subscription", kind: "message", T: ProductModel },
    { no: 2, name: "IsAutoRenewal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "IsManualActivation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "IsAutoCharge", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.EffectSubscriptionRenew
 */
export const EffectSubscriptionRenew = proto3.makeMessageType(
  "com.diagnocat.billing_new.EffectSubscriptionRenew",
  () => [
    { no: 1, name: "RenewSubscriptionStateID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.EffectSubscriptionActivate
 */
export const EffectSubscriptionActivate = proto3.makeMessageType(
  "com.diagnocat.billing_new.EffectSubscriptionActivate",
  () => [
    { no: 1, name: "ActivateSubscriptionStateID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.EffectSubscriptionReplace
 */
export const EffectSubscriptionReplace = proto3.makeMessageType(
  "com.diagnocat.billing_new.EffectSubscriptionReplace",
  () => [
    { no: 1, name: "ReplaceSubscriptionStateID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "NewSubscription", kind: "message", T: ProductModel },
    { no: 3, name: "IsAutoRenewal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "IsAutoCharge", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.EffectAddonAdd
 */
export const EffectAddonAdd = proto3.makeMessageType(
  "com.diagnocat.billing_new.EffectAddonAdd",
  () => [
    { no: 1, name: "ToSubscriptionStateID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Addon", kind: "message", T: ProductModel },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.EffectPackageAdd
 */
export const EffectPackageAdd = proto3.makeMessageType(
  "com.diagnocat.billing_new.EffectPackageAdd",
  () => [
    { no: 1, name: "Package", kind: "message", T: ProductModel },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.EffectBonusAdd
 */
export const EffectBonusAdd = proto3.makeMessageType(
  "com.diagnocat.billing_new.EffectBonusAdd",
  () => [
    { no: 1, name: "Addon", kind: "message", T: ProductModel },
    { no: 2, name: "ToSubscriptionStateID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "AdditionalMonths", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

