import { Button, Card, notification, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';
import { billingModel } from '@/entities/billing';
import {
  MarkAsPaidModal,
  useMarkAsPaidModal,
} from '@/features/markAsPaidModal';
import { BASE_URI } from '@/shared/config';

import { BillingHistoryOrderData, useBillingHistory } from '../../hooks';
import { BillingHistoryCell } from '../billingHistoryCell/BillingHistoryCell';

import styles from './BillingHistory.module.scss';

const { Title } = Typography;

const selectOrdersLoadingState =
  billingModel.selectors.getLoadingStateSelector('orders');

export const BillingHistory = () => {
  const { formatMessage } = useIntl();

  const billingHistoryData = useBillingHistory();
  const ordersLoadingState = useAppSelector(selectOrdersLoadingState);
  const { orderID, visible, openModal, closeModal } = useMarkAsPaidModal();

  const handleDownloadInvoice = async (invoiceId: string) => {
    try {
      const response = await fetch(
        `${BASE_URI}/api/billing/order/${invoiceId}/invoice/download?returnURL=true`,
        {
          credentials: 'include',
        },
      );

      if (response.ok) {
        const imageURL = await response.text();
        const blob = await fetch(imageURL, { credentials: 'same-origin' }).then(
          (imgResponse) => imgResponse.blob(),
        );

        const downloadLink = Object.assign(document.createElement('a'), {
          href: window.URL.createObjectURL(blob),
          download: `invoice_${invoiceId}`,
        });

        downloadLink.click();
      } else {
        notification.error({
          message: formatMessage({
            id: 'markAsPaidModal.error',
            defaultMessage: 'Something went wrong',
          }),
        });
      }
    } catch (error) {
      notification.error({
        message: formatMessage({
          id: 'global.network.error',
          defaultMessage: 'Network error',
        }),
      });
    }
  };

  const columns: ColumnsType<BillingHistoryOrderData> = [
    {
      key: 'date',
      dataIndex: 'date',
      render: (text, record) => (
        <BillingHistoryCell
          withTooltip
          tooltipText={record.id}
          tooltipTrigger="click"
          className={styles.firstCell}
          text={text}
          label={formatMessage({
            id: 'billingHistory.label.date',
            defaultMessage: 'Order issuing date',
          })}
        />
      ),
    },
    {
      key: 'subject',
      dataIndex: 'subject',
      render: (text) => (
        <BillingHistoryCell
          withTooltip
          text={text}
          label={formatMessage({
            id: 'billingHistory.label.subject',
            defaultMessage: 'Subject',
          })}
        />
      ),
    },
    {
      key: 'statusText',
      dataIndex: 'statusText',
      render: (text) => (
        <BillingHistoryCell
          text={text}
          label={formatMessage({
            id: 'billingHistory.label.status',
            defaultMessage: 'Status',
          })}
        />
      ),
    },
    {
      key: 'action',
      dataIndex: 'action',
      render: (_, record) => {
        const isNewInvoice = record.status === 'new';

        return (
          <BillingHistoryCell
            withTooltip={!!record?.transactionNumber}
            text={record?.transactionNumber}
            label={formatMessage({
              id: 'billingHistory.label.action',
              defaultMessage: 'Invoice',
            })}
          >
            {isNewInvoice && (
              <Button type="link" onClick={() => openModal(record.id)}>
                {formatMessage({
                  id: 'billingHistory.markAsPaidButton',
                  defaultMessage: 'Mark as paid',
                })}
              </Button>
            )}

            {record.markedAsPaid && !record?.transactionNumber && (
              <Button
                type="link"
                onClick={() => handleDownloadInvoice(record.id)}
              >
                {formatMessage({
                  id: 'global.download',
                  defaultMessage: 'Download',
                })}
              </Button>
            )}
          </BillingHistoryCell>
        );
      },
    },
  ];

  return (
    <>
      <Card
        title={
          <Title level={3} style={{ marginBottom: 0 }}>
            <FormattedMessage
              id="billingHistory.title"
              defaultMessage="Billing History"
            />
          </Title>
        }
        bordered={false}
        style={{ height: '100%' }}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingTop: 1 }}
      >
        <Table
          size="small"
          showHeader={false}
          columns={columns}
          dataSource={billingHistoryData}
          loading={ordersLoadingState !== 'succeeded'}
          scroll={{ x: true }}
          pagination={false}
        />
      </Card>

      <MarkAsPaidModal
        orderID={orderID}
        visible={visible}
        onCancel={closeModal}
      />
    </>
  );
};
