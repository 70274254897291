// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/billing_new/svc_billing_new.proto (package com.diagnocat.billing_new, syntax proto3)
/* eslint-disable */

import { Empty, proto3 } from "@bufbuild/protobuf";
import { EffectAddonAdd, EffectBonusAdd, EffectPackageAdd, EffectProductStateRemove, EffectSubscriptionActivate, EffectSubscriptionAdd, EffectSubscriptionReplace, EffectSubscriptionSetAutoCharge, EffectSubscriptionSetAutoRenewal, Order } from "./dto_order_new_pb.js";
import { ProductModel, ProductState } from "./dto_product_new_pb.js";
import { Account, AccountBillingInformation } from "./dto_account_new_pb.js";
import { Consumption } from "./dto_consumption_new_pb.js";

/**
 * @generated from message com.diagnocat.billing_new.AccountInfoStreamReq
 */
export const AccountInfoStreamReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.AccountInfoStreamReq",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.AccountInfoStreamResp
 */
export const AccountInfoStreamResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.AccountInfoStreamResp",
  () => [
    { no: 1, name: "EndOfHistoricalOrder", kind: "message", T: Empty, oneof: "Update" },
    { no: 2, name: "HistoricalOrder", kind: "message", T: Order, oneof: "Update" },
    { no: 3, name: "EndOfHistoricalInventory", kind: "message", T: Empty, oneof: "Update" },
    { no: 4, name: "HistoricalInventory", kind: "message", T: ProductState, oneof: "Update" },
    { no: 10, name: "AccountInfo", kind: "message", T: Account, oneof: "Update" },
    { no: 11, name: "Order", kind: "message", T: Order, oneof: "Update" },
    { no: 12, name: "Inventory", kind: "message", T: ProductState, oneof: "Update" },
    { no: 13, name: "Consumption", kind: "message", T: Consumption, oneof: "Update" },
    { no: 14, name: "Ping", kind: "message", T: Empty, oneof: "Update" },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.CreateOrderReq
 */
export const CreateOrderReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.CreateOrderReq",
  () => [
    { no: 1, name: "AccountID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ToChargeImmediately", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "SubscriptionAdd", kind: "message", T: EffectSubscriptionAdd, oneof: "Effect" },
    { no: 4, name: "SubscriptionReplace", kind: "message", T: EffectSubscriptionReplace, oneof: "Effect" },
    { no: 5, name: "AddonAdd", kind: "message", T: EffectAddonAdd, oneof: "Effect" },
    { no: 6, name: "PackageAdd", kind: "message", T: EffectPackageAdd, oneof: "Effect" },
    { no: 7, name: "BonusAdd", kind: "message", T: EffectBonusAdd, oneof: "Effect" },
    { no: 8, name: "SubscriptionSetAutoRenewal", kind: "message", T: EffectSubscriptionSetAutoRenewal, oneof: "Effect" },
    { no: 9, name: "ProductStateRemove", kind: "message", T: EffectProductStateRemove, oneof: "Effect" },
    { no: 10, name: "SubscriptionActivate", kind: "message", T: EffectSubscriptionActivate, oneof: "Effect" },
    { no: 11, name: "SubscriptionSetAutoCharge", kind: "message", T: EffectSubscriptionSetAutoCharge, oneof: "Effect" },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.CreateOrderResp
 */
export const CreateOrderResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.CreateOrderResp",
  () => [
    { no: 1, name: "Order", kind: "message", T: Order },
    { no: 2, name: "InvoicePaymentURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.ProcessOrderReq
 */
export const ProcessOrderReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.ProcessOrderReq",
  () => [
    { no: 1, name: "OrderID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.ProcessOrderResp
 */
export const ProcessOrderResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.ProcessOrderResp",
  () => [
    { no: 1, name: "Order", kind: "message", T: Order },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.PayOrderReq
 */
export const PayOrderReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.PayOrderReq",
  () => [
    { no: 1, name: "OrderID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ToChargeImmediately", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.PayOrderResp
 */
export const PayOrderResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.PayOrderResp",
  () => [
    { no: 1, name: "Paid", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "Reaction" },
    { no: 2, name: "URL", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Reaction" },
    { no: 3, name: "ManualPaymentOnly", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "Reaction" },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.CreateAccountPaymentMethodReq
 */
export const CreateAccountPaymentMethodReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.CreateAccountPaymentMethodReq",
  () => [
    { no: 1, name: "AccountID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "SuccessURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "CancelURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.CreateAccountPaymentMethodResp
 */
export const CreateAccountPaymentMethodResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.CreateAccountPaymentMethodResp",
  () => [
    { no: 1, name: "URL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.DeleteAccountPaymentMethodReq
 */
export const DeleteAccountPaymentMethodReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.DeleteAccountPaymentMethodReq",
  () => [
    { no: 1, name: "AccountID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.DeleteAccountPaymentMethodResp
 */
export const DeleteAccountPaymentMethodResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.DeleteAccountPaymentMethodResp",
  [],
);

/**
 * @generated from message com.diagnocat.billing_new.GetAvailableProductsReq
 */
export const GetAvailableProductsReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.GetAvailableProductsReq",
  () => [
    { no: 1, name: "ByID", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Condition" },
    { no: 2, name: "ByAccountID", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Condition" },
    { no: 3, name: "ByBillingZone", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Condition" },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.GetAvailableProductsResp
 */
export const GetAvailableProductsResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.GetAvailableProductsResp",
  () => [
    { no: 1, name: "AvailableProducts", kind: "message", T: ProductModel, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.GetAllAvailableProductsReq
 */
export const GetAllAvailableProductsReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.GetAllAvailableProductsReq",
  [],
);

/**
 * @generated from message com.diagnocat.billing_new.GetAllAvailableProductsResp
 */
export const GetAllAvailableProductsResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.GetAllAvailableProductsResp",
  () => [
    { no: 1, name: "AvailableProducts", kind: "message", T: ProductModel, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.SetAccountBillingInformationReq
 */
export const SetAccountBillingInformationReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.SetAccountBillingInformationReq",
  () => [
    { no: 1, name: "AccountID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "BillingInformation", kind: "message", T: AccountBillingInformation },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.SetAccountBillingInformationResp
 */
export const SetAccountBillingInformationResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.SetAccountBillingInformationResp",
  () => [
    { no: 1, name: "Account", kind: "message", T: Account },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.SetTransactionNumberToOrderReq
 */
export const SetTransactionNumberToOrderReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.SetTransactionNumberToOrderReq",
  () => [
    { no: 1, name: "OrderID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "TransactionNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.SetTransactionNumberToOrderResp
 */
export const SetTransactionNumberToOrderResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.SetTransactionNumberToOrderResp",
  () => [
    { no: 1, name: "Order", kind: "message", T: Order },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.Test_SetAccountWithoutStripeInvoicesReq
 */
export const Test_SetAccountWithoutStripeInvoicesReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.Test_SetAccountWithoutStripeInvoicesReq",
  () => [
    { no: 1, name: "AccountID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "WithoutStripeInvoices", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.Test_SetAccountWithoutStripeInvoicesResp
 */
export const Test_SetAccountWithoutStripeInvoicesResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.Test_SetAccountWithoutStripeInvoicesResp",
  [],
);

/**
 * @generated from message com.diagnocat.billing_new.Test_ExpireSubscriptionAfterReq
 */
export const Test_ExpireSubscriptionAfterReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.Test_ExpireSubscriptionAfterReq",
  () => [
    { no: 1, name: "ProductStateID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Seconds", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.Test_ExpireSubscriptionAfterResp
 */
export const Test_ExpireSubscriptionAfterResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.Test_ExpireSubscriptionAfterResp",
  [],
);

/**
 * @generated from message com.diagnocat.billing_new.SetAccountBillingZonesReq
 */
export const SetAccountBillingZonesReq = proto3.makeMessageType(
  "com.diagnocat.billing_new.SetAccountBillingZonesReq",
  () => [
    { no: 1, name: "AccountID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "BillingZones", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.SetAccountBillingZonesResp
 */
export const SetAccountBillingZonesResp = proto3.makeMessageType(
  "com.diagnocat.billing_new.SetAccountBillingZonesResp",
  () => [
    { no: 1, name: "account", kind: "message", T: Account },
  ],
);

