import packageJSON from 'package.json';

export const BASE_URI =
  process.env.REACT_APP_ROOT_URI ||
  process.env.REACT_APP_BASE_URI ||
  window.location.origin;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'development';
export const APP_VERSION = packageJSON.version;

// sentry variables
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';
export const SENTRY_TRACES_SAMPLE_RATE =
  parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 0.2;
export const ERROR_TRACKING_ENABLED_ENVIRONMENTS = ['production', 'staging'];
export const IS_ERROR_TRACKING_ENABLED =
  ERROR_TRACKING_ENABLED_ENVIRONMENTS.includes(ENVIRONMENT);
export const CORS_POLICY = (process.env.REACT_APP_CORS_POLICY || 'same-origin') as RequestCredentials;
