import { FC, useState } from 'react';
import cn from 'classnames';
import { Button, Input, notification, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { ConnectError } from '@bufbuild/connect';

import { useAppDispatch } from '@/shared/hooks';
import { organizationModel } from '@/entities/organization';

import styles from './OrganizationComment.module.scss';

const { TextArea } = Input;
const { Text } = Typography;

type OrganizationCommentProps = {
  comment: string;
  organizationID: string;
  className?: string;
  testID?: string;
};

export const OrganizationComment: FC<OrganizationCommentProps> = (props) => {
  const { className, testID, comment, organizationID } = props;

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [isCommentEditorVisible, setIsCommentEditorVisible] =
    useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>(comment);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCancel = () => {
    setIsCommentEditorVisible(false);
  };

  const handleUpdateComment = async () => {
    setLoading(true);

    try {
      const { Organization } = await dispatch(
        organizationModel.thunks.setOrganizationComment({
          OrganizationID: organizationID,
          Comment: newComment,
        }),
      ).unwrap();

      organizationModel.actions.setNewestOrganization(Organization);
      handleCancel();
    } catch (error) {
      const parsedMessage = JSON.parse((error as ConnectError)?.rawMessage);

      notification.error({
        message:
          parsedMessage?.message ??
          parsedMessage?.text ??
          parsedMessage?.reason ??
          formatMessage({
            id: 'global.error.somethingWentWrong',
            defaultMessage: 'Something went wrong',
          }),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      {!isCommentEditorVisible &&
        (!comment ? (
          <Button
            type="primary"
            onClick={() => setIsCommentEditorVisible(true)}
          >
            {formatMessage({
              id: 'organization.comment.button.addComment',
              defaultMessage: 'Add comment',
            })}
          </Button>
        ) : (
          <div className={styles.commentContainer}>
            <Text>{comment}</Text>

            <Button
              className={styles.editCommentButton}
              type="primary"
              onClick={() => setIsCommentEditorVisible(true)}
            >
              {formatMessage({
                id: 'organization.comment.button.editComment',
                defaultMessage: 'Edit comment',
              })}
            </Button>
          </div>
        ))}

      {isCommentEditorVisible && (
        <div className={styles.textAreaContainer}>
          <TextArea
            value={newComment}
            autoSize={{ minRows: 3, maxRows: 5 }}
            onChange={(event) => setNewComment(event.target.value)}
          />

          <div className={styles.textAreaButtons}>
            <Button onClick={handleCancel} loading={loading}>
              {formatMessage({ id: 'global.cancel', defaultMessage: 'Cancel' })}
            </Button>

            <Button
              type="primary"
              onClick={handleUpdateComment}
              loading={loading}
            >
              {formatMessage({ id: 'global.save', defaultMessage: 'Save' })}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
