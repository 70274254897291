import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';
import { billingModel } from '@/entities/billing';

import { getOrderStatus, getOrderSubscriptionName, OrderStatus } from '../lib';
import { orderStatusText } from '../config/i18n';

export type BillingHistoryOrderData = {
  id: string;
  key: string;
  date: string;
  transactionNumber: string;
  subject: string;
  status: OrderStatus;
  statusText: string;
  markedAsPaid: boolean;
};
// TODO: Remove Number constructor when in protocol fix bigint
export const useBillingHistory = (): BillingHistoryOrderData[] => {
  const { formatDate, formatMessage } = useIntl();
  const orders = useAppSelector(billingModel.selectors.selectOrders);
  const products = useAppSelector(billingModel.selectors.selectInventory);

  return useMemo(
    () =>
      orders.map((order): BillingHistoryOrderData => {
        const billed = order.Kind.case === 'Billed' && order.Kind.value;
        const status = getOrderStatus({
          isPaid: billed.Paid,
          isOverdue: billed.Overdue,
        });

        return {
          id: order.ID,
          key: order.ID,
          date: formatDate(new Date(Number(order.Created.At.seconds) * 1000), {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }),
          subject: getOrderSubscriptionName(order, products),
          transactionNumber: order.Kind.case === 'Billed' ? order.Kind.value.TransactionNumber : '',
          status,
          statusText: formatMessage(orderStatusText[status]),
          markedAsPaid: order.Kind.case === 'Billed' ? order.Kind.value.MarkedAsPaid : false,
        };
      }).reverse(),
    [formatDate, formatMessage, orders, products],
  );
};
