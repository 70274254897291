import React, { FC } from 'react';
import cn from 'classnames';
import { Divider, Form, Input, Modal, Row, Select, Typography } from 'antd';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ProductModel } from 'protocol/api/billing_new/dto_product_new_pb';
import { ServiceGroupName } from 'protocol/api/billing_new/dto_services_new_pb';
import { ServicesWithGroups } from '@/pages/ProductEditor/ui/ServicesWithGroups/ServicesWithGroups';
import {
  productEditorModel,
  SERVICE_GROUP_2D_ANALYSIS,
  SERVICE_GROUP_2D_UPLOADS,
  SERVICE_GROUP_3D_ANALYSIS,
  SERVICE_GROUP_3D_UPLOADS,
} from '@/entities/productEditor';
import { Services } from '@/pages/ProductEditor/ui/Services/Services';
import { lifeTimeMonthDefaultMessages } from '@/shared/config/i18n';

import { CURRENCIES, LIFETIME_IN_MONTHS } from '../../config';

import styles from './EditorModal.module.scss';

const { Title } = Typography;

type EditorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  testID?: string;
};

const currencyOptions = CURRENCIES.map((currency) => ({
  value: currency,
  label: currency,
}));

export const EditorModal: FC<EditorModalProps> = (props) => {
  const { className, testID, isOpen, onClose } = props;

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [form] = Form.useForm();

  const productForUpdate = useAppSelector(
    productEditorModel.selectors.selectProductForUpdate,
  );

  const editorMode = useAppSelector(
    productEditorModel.selectors.selectEditorMode,
  );

  const billingZone = useAppSelector(productEditorModel.selectors.selectBillingZone);

  const isEditMode = editorMode === 'edit';

  const lifetimeOptions = LIFETIME_IN_MONTHS.map((lifetime) => ({
    value: lifetime,
    label: formatMessage(lifeTimeMonthDefaultMessages[lifetime]),
  }));

  const onSubmit = async () => {
    if (editorMode === 'edit') {
      dispatch(productEditorModel.actions.setOne(productForUpdate));
    } else {
      const {
        SKU,
        productName,
        productDescription,
        price,
        currency,
        lifetimeInMonths,
        lifetimeInDays,
      } = await form.validateFields();

      // @ts-expect-error WARN: resolve types later
      const newProduct = new ProductModel({
        ...productForUpdate,
        SKU,
        Name: productName,
        Description: productDescription,
        Price: price,
        Currency: currency,
        StripeProductID: '',
        BillingZone: billingZone,
        Kind: {
          case: productForUpdate?.Kind?.case ?? 'Subscription',
          value: {
            LifeTimeDays: Number(lifetimeInDays),
            LifeTimeMonths: Number(lifetimeInMonths),
          },
        },
      });

      dispatch(productEditorModel.actions.addOne(newProduct));
    }

    dispatch(
      productEditorModel.actions.setProductForUpdate({} as ProductModel),
    );
    dispatch(productEditorModel.actions.setEditorMode('edit'));
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      width="60vw"
      className={cn(styles.container, className)}
      data-testid={testID}
      centered
      okText={
        editorMode !== 'edit'
          ? formatMessage({
              id: 'global.add',
              defaultMessage: 'Add',
            })
          : formatMessage({
              id: 'global.update',
              defaultMessage: 'Update',
            })
      }
      cancelText={formatMessage({
        id: 'global.cancel',
        defaultMessage: 'Cancel',
      })}
      onOk={onSubmit}
      destroyOnClose
      onCancel={() => {
        dispatch(
          productEditorModel.actions.setProductForUpdate({} as ProductModel),
        );
        dispatch(productEditorModel.actions.setEditorMode('edit'));
        onClose();
      }}
    >
      <Form
        form={form}
        name="product-update-form"
        layout="vertical"
        initialValues={{
          SKU: productForUpdate.SKU,
          productName: productForUpdate.Name,
          productDescription: productForUpdate.Description,
          price: productForUpdate.Price,
          currency: productForUpdate.Currency,
          lifetimeInMonths:
            productForUpdate?.Kind?.case === 'Subscription' ||
            productForUpdate?.Kind?.case === 'Package'
              ? productForUpdate?.Kind?.value?.LifeTimeMonths
              : 0,
          lifetimeInDays:
            productForUpdate?.Kind?.case === 'Subscription' ||
            productForUpdate?.Kind?.case === 'Package'
              ? productForUpdate?.Kind?.value?.LifeTimeDays
              : 0,
        }}
      >
        <Title level={3}>
          {formatMessage({
            id: 'productEditor.editor.title',
            defaultMessage: 'Edit product',
          })}
          {': '}
          {productForUpdate.Name}
        </Title>

        <Form.Item
          name="SKU"
          label="SKU"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Input
            disabled={isEditMode}
            placeholder={formatMessage({
              id: 'productEditor.editor.SKU.placeholder',
              defaultMessage: 'Enter product SKU',
            })}
          />
        </Form.Item>

        <Form.Item
          name="productName"
          label={formatMessage({
            id: 'productEditor.editor.productName',
            defaultMessage: 'Product name',
          })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Input
            disabled={isEditMode}
            placeholder={formatMessage({
              id: 'productEditor.editor.productName.placeholder',
              defaultMessage: 'Enter product name',
            })}
          />
        </Form.Item>

        <Form.Item
          name="productDescription"
          label={formatMessage({
            id: 'productEditor.editor.productDescription',
            defaultMessage: 'Product description',
          })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Input
            disabled={isEditMode}
            placeholder={formatMessage({
              id: 'productEditor.editor.productDescription.placeholder',
              defaultMessage: 'Enter product description',
            })}
          />
        </Form.Item>

        <Form.Item
          name="price"
          label={formatMessage({
            id: 'productEditor.editor.price',
            defaultMessage: 'Price',
          })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Input
            type="number"
            min={0}
            disabled={isEditMode}
            placeholder={formatMessage({
              id: 'productEditor.editor.price.placeholder',
              defaultMessage: 'Enter product price',
            })}
          />
        </Form.Item>

        <Form.Item
          name="currency"
          label={formatMessage({
            id: 'productEditor.editor.currency',
            defaultMessage: 'Currency',
          })}
        >
          <Select
            style={{ width: '350px' }}
            disabled={isEditMode}
            placeholder={formatMessage({
              id: 'productEditor.editor.currency.placeholder',
              defaultMessage: 'Select product currency',
            })}
            className={styles.billingZoneSelect}
            options={currencyOptions}
          />
        </Form.Item>

        <Form.Item
          name="lifetimeInMonths"
          label={formatMessage({
            id: 'productEditor.editor.lifetimeInMonths',
            defaultMessage: 'Lifetime in months',
          })}
        >
          <Select
            style={{ width: '350px' }}
            disabled={isEditMode}
            placeholder={formatMessage({
              id: 'productEditor.editor.lifetimeInMonths.placeholder',
              defaultMessage: 'Select lifetime in months',
            })}
            className={styles.billingZoneSelect}
            options={lifetimeOptions}
          />
        </Form.Item>

        <Form.Item
          name="lifetimeInDays"
          label={formatMessage({
            id: 'productEditor.editor.lifetimeInDays',
            defaultMessage: 'Lifetime in days',
          })}
        >
          <Input
            type="number"
            min={0}
            disabled={isEditMode}
            placeholder={formatMessage({
              id: 'productEditor.editor.lifetimeInDays.placeholder',
              defaultMessage: 'Select lifetime in days',
            })}
          />
        </Form.Item>

        <Row gutter={16} style={{ rowGap: 24 }}>
          <ServicesWithGroups
            services={SERVICE_GROUP_2D_ANALYSIS}
            groupName={ServiceGroupName.ServiceGroupNameAnalysis2D}
          />
        </Row>

        <Row gutter={16} style={{ rowGap: 24 }}>
          <ServicesWithGroups
            services={SERVICE_GROUP_3D_ANALYSIS}
            groupName={ServiceGroupName.ServiceGroupNameAnalysis3D}
          />
        </Row>

        <Row gutter={16} style={{ rowGap: 24 }}>
          <ServicesWithGroups
            services={SERVICE_GROUP_2D_UPLOADS}
            groupName={ServiceGroupName.ServiceGroupNameUpload2D}
          />
        </Row>

        <Row gutter={16} style={{ rowGap: 24 }}>
          <ServicesWithGroups
            services={SERVICE_GROUP_3D_UPLOADS}
            groupName={ServiceGroupName.ServiceGroupNameUpload3D}
          />
        </Row>
        <Divider />
        <Row gutter={16} style={{ rowGap: 24 }}>
          <Title level={5} style={{ paddingLeft: '8px' }}>
            {formatMessage({
              id: 'productEditor.editor.services.emptyGroup.title',
              defaultMessage: 'Services without group',
            })}
          </Title>
        </Row>

        <Row gutter={16} style={{ rowGap: 24 }}>
          <Services />
        </Row>
      </Form>
    </Modal>
  );
};
