import api, { OrganizationService } from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import {
  SetOrganizationCommentReq,
  SetOrganizationCommentResp,
} from 'protocol/api/core/svc_organization_pb';

type OrganizationRequests = keyof typeof OrganizationService['methods'];

const generateOrganizationThunk = createThunkGenerator<OrganizationRequests>(
  SliceName.organization,
);

export const setOrganizationComment = generateOrganizationThunk<
  SetOrganizationCommentReq,
  SetOrganizationCommentResp
>('setOrganizationComment', api.organization.setOrganizationComment);
