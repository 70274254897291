import { defineMessages } from 'react-intl';

import { UserLocale } from './types';
import { ENVIRONMENT } from './config';

export enum LocalStorageKey {
  locale = 'locale',
}

export const DEFAULT_LOCALE: UserLocale = 'en-US';

export enum SliceName {
  auth = 'auth',
  user = 'user',
  users = 'users',
  billing = 'billing',
  organization = 'organization',
  productEditor = 'productEditor',
}

export enum ToothStatus {
  unhealthy = 'unhealthy',
  lowProbability = 'lowProbability',
  treated = 'treated',
  healthy = 'healthy',
  missing = 'missing',
}

export enum ToothIconPathByToothStatus {
  unhealthy = 'Unhealthy',
  lowProbability = 'LowProbability',
  treated = 'Treated',
  healthy = 'Healthy',
}

export enum PrintType {
  blackAndWhite = 'black-and-white',
  colored = 'colored',
}

export const MOLAR_TOOTH_NUMBER = [
  18, 17, 16, 28, 27, 26, 38, 37, 36, 48, 47, 46,
];

export const DEFAULT_MEDICAL_IMAGE_HEIGHT = 140;

export const SUB_IMAGE_CROPPED_CONTEXT_PADDING = 10;

export enum BackToButtonText {
  report = 'Back to report',
  patientProfile = 'Back to patient profile',
  patientList = 'Back to patient list',
}

export enum ToastErrors {
  errorHeading = 'errorHeading',
}
export const toastErrors = defineMessages<ToastErrors>({
  errorHeading: {
    id: 'error.heading',
    defaultMessage: 'Error',
  },
});

export const BILLING_ZONE_WITHOUT_STRIPE_REGEXP = /cis|israel/i;
