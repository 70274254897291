// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrganizationComment-module__commentContainer--ECJ\\+I62B{display:grid;grid-template-rows:1fr 1fr;gap:12px}.OrganizationComment-module__editCommentButton--\\+\\+GhH2eo{justify-self:end}.OrganizationComment-module__textAreaContainer--SUxJMXsb{display:flex;flex-direction:column;gap:12px}.OrganizationComment-module__textAreaButtons--5oRMVgAA{display:flex;justify-content:flex-end;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/Organization/ui/OrganizationComment/OrganizationComment.module.scss"],"names":[],"mappings":"AAAA,yDACE,YAAA,CACA,0BAAA,CACA,QAAA,CAGF,2DACE,gBAAA,CAGF,yDACE,YAAA,CACA,qBAAA,CACA,QAAA,CAIF,uDACE,YAAA,CACA,wBAAA,CACA,OAAA","sourcesContent":[".commentContainer {\n  display: grid;\n  grid-template-rows: 1fr 1fr ;\n  gap: 12px;\n}\n\n.editCommentButton {\n  justify-self: end;\n}\n\n.textAreaContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n}\n\n.textAreaButtons {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentContainer": "OrganizationComment-module__commentContainer--ECJ+I62B",
	"editCommentButton": "OrganizationComment-module__editCommentButton--++GhH2eo",
	"textAreaContainer": "OrganizationComment-module__textAreaContainer--SUxJMXsb",
	"textAreaButtons": "OrganizationComment-module__textAreaButtons--5oRMVgAA"
};
export default ___CSS_LOADER_EXPORT___;
