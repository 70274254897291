import {
  Breadcrumb,
  Card,
  Col,
  Descriptions,
  Row,
  Space,
  Typography,
} from 'antd';
import { generatePath, Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';

import { Layout } from '@/shared/ui';
import { BillingHistory } from '@/widgets/billingHistory';
import { CurrentSubscription } from '@/widgets/currentSubscription';
import { Packages } from '@/widgets/packages';
import { BillingInfo } from '@/widgets/billingInfo';
import { OrganizationUserRoleType } from 'protocol/model/dto_organization_pb';
import { AccountStripeSwitch } from '@/features/accountStripeSwitch';
import { useAppSelector } from '@/shared/hooks';
import { organizationModel } from '@/entities/organization';
import { FeatureFlags } from '@/widgets/featureFlags';
import { SelectBillingZone } from '@/widgets/selectBillingZone';
import styles from '@/pages/User/User.module.scss';
import { PATHS } from '@/shared/config';
import { userModel } from '@/entities/user';
import { OrganizationComment } from '@/pages/Organization/ui/OrganizationComment/OrganizationComment';

const { Title } = Typography;

export const Organization = () => {
  const { organizationID, userID } =
    useParams<{ organizationID: string; userID: string }>();
  const { formatDate, formatMessage } = useIntl();

  const organization = useAppSelector(
    organizationModel.selectors.selectOrganization,
  );
  const loading = useAppSelector(
    organizationModel.selectors.selectOrganizationLoading,
  );

  const currentUser = useAppSelector(userModel.selectors.selectCurrentUser);

  const currentOwner = organization?.UserRoles?.find(({ Roles }) =>
    Roles.some(
      (role) => role === OrganizationUserRoleType.OrganizationUserRoleTypeOwner,
    ),
  );

  return (
    <Layout.Content>
      <Breadcrumb
        style={{ padding: '24px 0' }}
        className="p2"
        items={[
          {
            title: (
              <Link className={styles.breadcrumb} to={generatePath(PATHS.main)}>
                {formatMessage({
                  id: 'breadcrumb.home',
                  defaultMessage: 'Home',
                })}
              </Link>
            ),
          },
          {
            title: currentUser && (
              <Link
                to={generatePath(PATHS.user, {
                  userID,
                })}
                className={styles.breadcrumb}
              >
                {`${currentUser?.PersonalData?.FirstName ?? ''} ${
                  currentUser?.PersonalData?.LastName ?? ''
                }`}
              </Link>
            ),
          },
          {
            title: `${organization?.Name ?? ''}`,
          },
        ]}
      />

      <Row gutter={16} style={{ rowGap: 24 }}>
        <Col md={24} lg={12}>
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <Card
              style={{ height: '100%' }}
              title={
                <Title level={3} style={{ marginBottom: 0 }}>
                  <FormattedMessage
                    id="organization.title"
                    defaultMessage="Organization Information"
                  />
                </Title>
              }
              bordered={false}
              loading={loading !== 'succeeded'}
              extra={<AccountStripeSwitch />}
            >
              <Descriptions column={1} size="small">
                <Descriptions.Item
                  label={
                    <FormattedMessage
                      id="organization.name"
                      defaultMessage="Name"
                    />
                  }
                >
                  {organization?.Name}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <FormattedMessage
                      id="organization.id"
                      defaultMessage="ID"
                    />
                  }
                >
                  {organization?.ID}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <FormattedMessage
                      id="global.created"
                      defaultMessage="Created"
                    />
                  }
                >
                  {organization?.Created?.At?.seconds &&
                    formatDate(
                      new Date(
                        Number(organization?.Created?.At?.seconds) * 1000,
                      ),
                      {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                      },
                    )}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <FormattedMessage
                      id="organization.owner.name"
                      defaultMessage="Owner name"
                    />
                  }
                >
                  {currentOwner &&
                    `${currentOwner.PersonalData.FirstName} ${currentOwner.PersonalData.LastName}`}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <FormattedMessage
                      id="organization.owner.email"
                      defaultMessage="Owner email"
                    />
                  }
                >
                  {currentOwner?.PersonalData?.Email}
                </Descriptions.Item>
              </Descriptions>

              <SelectBillingZone />

              {organization &&
                <OrganizationComment
                  organizationID={organizationID}
                  comment={organization?.Comment}
                />
              }
            </Card>

            <BillingInfo />

            <BillingHistory />
          </Space>
        </Col>

        <Col md={24} lg={12}>
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <CurrentSubscription
              organizationID={organizationID}
              userID={userID}
            />

            <Packages organizationID={organizationID} userID={userID} />

            <FeatureFlags />
          </Space>
        </Col>
      </Row>
    </Layout.Content>
  );
};
