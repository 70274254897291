import cn from 'classnames';
import { Tooltip } from 'antd';

import styles from './BillingHistoryCell.module.scss';

type BillingHistoryCellProps = {
  className?: string;
  text?: string;
  label: string;
  withTooltip?: boolean;
  tooltipText?: string;
  children?: React.ReactNode;
  tooltipTrigger?: 'hover' | 'click';
};

export const BillingHistoryCell = ({
  className,
  text,
  label,
  withTooltip = false,
  children,
  tooltipText,
  tooltipTrigger = 'hover',
}: BillingHistoryCellProps) => (
  <div className={cn(styles.container, className)}>
    <span className={styles.label}>{label}</span>

    {withTooltip ? (
      <Tooltip title={tooltipText || text} trigger={tooltipTrigger} placement="topLeft">
        <span className={styles.text}>{text}</span>
      </Tooltip>
    ) : (
      <span className={styles.text}>{text}</span>
    )}
    {children}
  </div>
);
