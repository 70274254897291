import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Typography,
  Upload,
  UploadFile,
} from 'antd';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { ConnectError } from '@bufbuild/connect';

import { useAppDispatch } from '@/shared/hooks';
import { billingModel } from '@/entities/billing';
import { BASE_URI } from '@/shared/config';

import styles from './MarkAsPaidModal.module.scss';

const { Text } = Typography;

export const PAYMENT_UPLOAD_MAX_FILE_SIZE = 2 * 1024 * 1024;
export const PAYMENT_UPLOAD_AVAILABLE_TYPES = '.png, .jpg, .jpeg, .pdf';

type MarkAsPaidVariant = 'transactionNumber' | 'uploadReceipt';

type MarkAsPaidFormFields = {
  markAsPaidVariant: MarkAsPaidVariant;
  paymentID: string;
  uploadReceipt: {
    file: File,
    fileList: UploadFile[];
  };
};

type MarkAsPaidModalProps = {
  orderID: string;
  visible: boolean;
  onCancel: () => void;
};

const uploadPaymentFile = async ({
  receiptFile,
  orderID,
}: {
  receiptFile: File,
  orderID: string;
}): Promise<void> => {
  const body = await receiptFile.arrayBuffer();

  await fetch(`${BASE_URI}/api/billing/order/${orderID}/invoice/upload`, {
    method: 'PUT',
    body,
    headers: {
      'Content-Type': receiptFile.type,
    },
    credentials: 'include',
  });
};

export const MarkAsPaidModal = (props: MarkAsPaidModalProps) => {
  const { orderID, visible, onCancel } = props;

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<MarkAsPaidFormFields>();
  const markAsPaidVariant: MarkAsPaidVariant = Form.useWatch('markAsPaidVariant', form);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();

      if (values.markAsPaidVariant === 'transactionNumber') {
        await dispatch(billingModel.thunks.setTransactionNumberToOrder({ OrderID: orderID, TransactionNumber: values.paymentID })).unwrap();
      } else {
        await uploadPaymentFile({ receiptFile: values.uploadReceipt.file, orderID });
      }

      await dispatch(billingModel.thunks.processOrder({ OrderID: orderID })).unwrap();

      notification.success({ message: formatMessage({ id: 'markAsPaidModal.success', defaultMessage: 'Order successfully marked as paid' }) });
    } catch (error: unknown) {
      const { message } = error as ConnectError;

      notification.error({ message: message || formatMessage({ id: 'markAsPaidModal.error', defaultMessage: 'Something went wrong' }) });
    } finally {
      setLoading(false);
      onCancel();
    }
  };

  return (
    <Modal
      title={formatMessage({
        id: 'markAsPaidModal.title',
        defaultMessage: 'Mark as Paid',
      })}
      open={visible}
      onCancel={onCancel}
      okText={formatMessage({
        id: 'markAsPaidModal.okText',
        defaultMessage: 'Save',
      })}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
    >
      <Form
        name="migrateAccountForm"
        form={form}
        layout="vertical"
        initialValues={{
          markAsPaidVariant: 'transactionNumber',
        }}
        disabled={loading}
      >
        <Form.Item name="markAsPaidVariant">
          <Radio.Group size="large" className={styles.radioGroup}>
            <Radio value="transactionNumber">
              <Text>
                {formatMessage({
                  id: 'markAsPaidModal.transactionNumberLabel',
                  defaultMessage: 'Enter transaction number',
                })}
              </Text>
            </Radio>

            <Form.Item
              name="paymentID"
              rules={[
                {
                  required: markAsPaidVariant === 'transactionNumber',
                  message: formatMessage({
                    id: 'markAsPaidModal.paymentIDPlaceholder',
                    defaultMessage: 'Please input the transaction number',
                  }),
                },
              ]}
            >
              <Input disabled={markAsPaidVariant === 'uploadReceipt'} />
            </Form.Item>

            <Radio value="uploadReceipt">
              <Text>
                {formatMessage({
                  id: 'markAsPaidModal.uploadReceiptLabel',
                  defaultMessage: 'Upload a reciept',
                })}
              </Text>
            </Radio>

            <Form.Item
              name="uploadReceipt"
              extra={formatMessage({
                id: 'markAsPaidModal.uploadExtra',
                defaultMessage: 'png, jpg, jpeg, pdf up to 2mb',
              })}
              rules={[
                {
                  required: markAsPaidVariant === 'uploadReceipt',
                  message: formatMessage({
                    id: 'markAsPaidModal.uploadReceipt.required',
                    defaultMessage: 'Please add a file',
                  }),
                },
              ]}
            >
              <Upload
                beforeUpload={() => false}
                maxCount={1}
                disabled={markAsPaidVariant === 'transactionNumber'}
                accept={PAYMENT_UPLOAD_AVAILABLE_TYPES}
                // showUploadList={false}
              >
                <Button
                  disabled={markAsPaidVariant === 'transactionNumber'}
                  icon={<UploadOutlined rev={undefined} />}
                >
                  {formatMessage({
                    id: 'markAsPaidModal.uploadButton',
                    defaultMessage: 'Choose a file from computer',
                  })}
                </Button>
              </Upload>
            </Form.Item>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
